import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'AdminLogin',
    component: () => import(/* webpackChunkName: "about" */ '../components/AdminLogin'),


  },
  {
    path: '/',
    redirect: {
      name: "AdminLogin"
    }
  },


  {
    path: "/login",
    name: "AdminLogin",
    component: () => import(/* webpackChunkName: "about" */ '../components/AdminLogin'),
  },
 


  {
    path: '/home',
    name: 'HomeView',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      console.log(to)
      console.log(from)
      if(localStorage.status == 1 || localStorage.status == '1') {
        next();
      } else {
        next('/login');
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
