<template>
  <div class="home">
  
    <DashboardView/>
  </div>
</template>

<script>
// @ is an alias to /src
import DashboardView from '@/components/DashboardView.vue'


export default {
  name: 'HomeView',
  components: {
    DashboardView
  }
}
</script>
