<template>
    <main v-if="isLoading===true">
          <span class="loader"></span>
    </main>
    <div class="mainSec" style="height:90vh;">
        <div style="display:flex;width:100%;padding-bottom: 1rem;">
            <span style="text-align: left;font-size: 23px;">Dashboard</span>
     <span style ="position: absolute;
right: 128px;">   <select class="form-select"  @change="apiCalls(selectedDriverKey)" aria-label="Default select example"  v-model="selectedDriverKey" style="margin-bottom: 1rem;width:200px;">
                            <option value=""  selected>All City</option>

                            <option v-for="(i,ind) in getOrderDataKEy" :key="ind" >
                                {{i}}
                            </option>

                        </select>
                    </span>
       <span style="text-align: right;margin-bottom: 1rem;margin-left:2rem;font-size: 14px;position: absolute;right:27px;" class="btn btn-danger" @click="logOutFunc()">Logout</span>
       

        </div>
     <div class="container-fluid mainSecQ example"  style="height:100%;overflow-x: scroll;">


            <div class="component mainSec2">
                
                <div class="row">
                   

                    <div class="col-md-9">
                        <span class="heads">Live Updates</span>
                        <div class=" mainSec2">
                            <div class="row UpdatesSec">
                                <div class="col-md-3 Updates Updatesborder">
                                    <span class="heads">Total Order Count</span><br>
                                    <span class="values">{{this.totalOrderCount}}</span>

                                </div>
                                <div class="col-md-3 Updates Updatesborder">
                                    <span class="heads">Pending Order</span><br>
                                    <span class="values" style="color:red;">{{this.pangingOrderCount}}</span>
                                </div>
                                <div class="col-md-3 Updates Updatesborder">
                                    <span class="heads">Assigned Order</span><br>
                                    <span class="values">{{this.assignOrderCount}}</span>
                                </div>
                                <div class="col-md-3 Updates">
                                    <span class="heads">Successful Order</span><br>
                                    <span class="values">{{this.successfulOrderCount}}</span>
                                </div>
                            </div>
                        </div>
                        <div class=" buttonSec">
                            <div class="" style="display:flex;justify-content: space-around;">
                               
                            <button @click="pendingValue('pending')"  ref="autoClick" type="button" :class="Value === 'pending'?'btn btn-primary btnActive':'btn btn-primary '">Pending Order</button>
                            <button @click="AcceptedValue('accepted')" type="button" :class="Value === 'accepted'?'btn btn-primary btnActive':'btn btn-primary '">Accepted Order</button>
                            <button type="button" @click="AssignedValue('assigned')" :class="Value === 'assigned'?'btn btn-primary btnActive':'btn btn-primary '">Assigned Order</button>
                            <button type="button" @click="ExceedTimeValue('ExceedTime')" :class="Value === 'ExceedTime'?'btn btn-primary btnActive':'btn btn-primary '" >Exceeded Time</button>
                            <button type="button"  @click="DeclinedValue('declined')" :class="Value === 'declined'?'btn btn-primary btnActive':'btn btn-primary '" >Cancelled Order</button>
                            <button type="button"  @click="SucessValue('completed')" :class="Value === 'completed'?'btn btn-primary btnActive':'btn btn-primary '" >Successful Order</button>
                            <button type="button" @click="preeOrderValur('Pre-Order')"  :class="Value === 'Pre-Order'?'btn btn-primary btnActive':'btn btn-primary '"  >Pre Order</button>
                            <button type="button"  @click="SelfOrder('takeaway')"  :class="Value === 'takeaway'?'btn btn-primary btnActive':'btn btn-primary '"   class="btn btn-primary">Self Order</button>
                            <button type="button"  @click="ChValur('closeMerchant')"  :class="Value === 'closeMerchant'?'btn btn-primary btnActive':'btn btn-danger '" >C/H Merchant</button>

                                

                            </div>
                            
                        </div>
                        <div v-if="Value === 'closeMerchant'" class=" mainSec2">

                            <div class="col.md-12">
                        <select class="form-select" aria-label="Default select example"  v-model="SelectMErchantType" style="margin-bottom: 1rem;width:220px;">
                            <option value="Close Holiday Merchant" selected>Close Holiday Merchant</option>
                           
                            <option value="Holiday Merchant">Holiday Merchant</option>
                           

                        </select>
                    </div>
                            
                            <div class="table-responsive" v-if="SelectMErchantType === 'Close Holiday Merchant'">
							<table class="table table-striped">
								<thead>
									<tr>
										<th scope="col">Ref#</th>
										<th scope="col">Date</th>
										<th scope="col">Merchant ID</th>
										<th scope="col">Merchant Name</th>
										<th scope="col">Cite</th>
                                        <th scope="col">Close From</th>
                                        <th scope="col">Remarks</th>
                                      

									</tr>
								</thead>
								<tbody >
									<tr v-for="(data,ind) in closeMerchantDatalist" :key="ind">
										<th scope="row">{{ind+1}}</th>
										<td>none</td>
										<td>{{data.store_id}}</td>
										<td>{{ data.store_name }}</td>
                                        <td>{{data.city}}</td>
										<td>none</td>
                                        <td>none</td>
										
										
									</tr>
								
								</tbody>
							</table>
						</div>
                        <div class="table-responsive"  v-if="SelectMErchantType === 'Holiday Merchant'">
							<table class="table table-striped">
								<thead>
									<tr>
										<th scope="col">Ref#</th>
										<th scope="col">Date</th>
										<th scope="col">Merchant ID</th>
										<th scope="col">Merchant Name</th>
										<th scope="col">Cite</th>
                                        <th scope="col">Close From</th>
                                        <th scope="col">Remarks</th>
                                        

									</tr>
								</thead>
								<tbody>
                                    <tr v-for="(data,ind) in holidayMerchantDatalist" :key="ind">
										<th scope="row">{{ind+1}}</th>
										<td>none</td>
										<td>{{data.store_id}}</td>
										<td>{{ data.store_name }}</td>
                                        <td>{{data.city}}</td>
										<td>none</td>
                                        <td>none</td>
										
									
									</tr>
									
								</tbody>
							</table>
						</div>

                        </div>

                        <div v-else class="mainSec2">
                            <div class="row UpdatesSec"  style="position: relative;">
                               
                            
                               
                               
                               
                                <div >
                                    <form  class="example"  style="max-width:300px">
                                        <input type="text" v-model="serData" placeholder="Search.." name="search2">

                                    </form>
                                </div>
                                <div v-if=" this.displayOrdersData.length == 0 " class="col-md-12" style="text-align:center;padding-top: 2rem;height:200px;">

                                     <h2>There is No any order yet...</h2>

                                </div>
                          
                                <div class="col-md-4"  v-for="(data,ind) in displayedItems"  :key="ind" style="margin-bottom: 2rem;" >
                                    <div class="card" style="margin:2rem 0rem;">
                                        <div class="card-header">
                                            <div style="display:flex">
                                                <div style="width:65%;text-align: left;font-size: 16px;"><b>{{data.city}}</b></div>
                                                <div style="width:35%"><button class="btn btn-success">{{data.status}}</button></div>
                                                
                                            </div>



                                        </div>
                                        <div class="card-body" >
                                            <p>Booking ID :  <b>{{data.order_no}}</b></p>
                                            <p>Store Name: <b>{{data.store_name}}</b></p>
                                            <p>Customer Name :  <b>{{data.customer_name}}</b></p>
                                            <p>Delivery Status :  <b>Eatura</b></p>
                                            <p>Payment Mode :  <b>{{data.payment_type}}</b></p>
                                            <p>Amount :  <b>€ {{data.grand_total}}</b></p>
                                            <p>Order Type :  <b>{{data.order_type}}</b></p>
                                            <p>Time Date :  <b>{{data.create_datetime}}</b></p>
                                            <p>Distance :  <b>{{data.distance}}-KM</b></p>
                                            <div class="buttonSec1" style="display:flex;justify-content: space-around;">
                                                <button v-if="(data.status === 'accepted'|| data.status === 'assigned' || data.status === 'pending') && Value !=='ExceedTime'  " type="button" class="btn  cardBtn" data-toggle="modal" :data-target="data.status=='accepted' || data.status == 'assigned' ? ('#exampleModalCenteraccepted'+ ind): ('#exampleModalCenter' + data.order_type + ind)" style="background: #0b7dda;color:#fff">Edit</button>

                                                <!-- Modal -->
                                                <div class="modal fade" :id="('exampleModalCenteraccepted'+ ind)" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                   
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLongTitle">Change Order Status</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <label>Sucess Or decline Order</label><br>
                                                           


                                                            </div>
                                                            <div class="modal-footer">

                                                                <button type="button" class="btn btn-success close"  data-dismiss="modal" aria-label="Close" @click="ChangeOrderStatusApi('complete_order','sucess',data.order_no)">Sucessful</button>
                                                                <button type="button" class="btn btn-danger close"  data-dismiss="modal" aria-label="Close" @click="declinedOrder('cancel_order','declined',data.order_no)">Declined</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal fade" :id="('exampleModalCenterPre-Order' + ind)" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLongTitle">Change Order Status</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <label>Accept Or decline Order</label><br>
                                                           


                                                            </div>
                                                            <div class="modal-footer">

                                                                <button type="button" class="btn btn-success close"  data-dismiss="modal" aria-label="Close" @click="ChangeOrderStatusApi('accept_order','accepted',data.order_no)">Accepted</button>
                                                                <button type="button" class="btn btn-danger close"  data-dismiss="modal" aria-label="Close" @click="declinedOrder('cancel_order','declined',data.order_no)">Declined</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="modal fade" :id="('exampleModalCenterASAP' + ind)" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLongTitle">Change Order Status</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <label>Estimation: {{Settimes}}</label><br>
                                                               <div style="display:flex">
                                                                   <button class="btn btn-danger  timesBtn" @click="getButtonValue(20)">20</button>
                                                                   <button class="btn btn-danger timesBtn" @click="getButtonValue(40)">40</button>
                                                                   <button class="btn btn-danger  timesBtn" @click="getButtonValue(60)">60</button>

                                                               </div>
                                                                <!-- <label>Remark</label><br>
                                                                <textarea style="width:100%;resize: none"></textarea> -->


                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-success close"  data-dismiss="modal" aria-label="Close" @click="ChangeOrderStatusApi('accept_order','accepted',data.order_no)">Accepted</button>
                                                                <button type="button" class="btn btn-danger close"  data-dismiss="modal" aria-label="Close" @click="declinedOrder('cancel_order','declined',data.order_no)">Declined</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                


                                                <button type="button" class="btn  cardBtn" @click="orderViewApi(data.order_no)"  data-toggle="modal" :data-target="('#exampleModalLong'+ ind)" style="background:lightseagreen;color:#fff">View</button>



                                                <!-- Modal -->
                                                <div class="modal fade" :id="('exampleModalLong'+ ind)" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div class="modal-dialog" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLongTitle">Order info</h5>

                                                                    <h5 class="modal-title" id="exampleModalLongTitle">Order Id:- {{orderView.order_id}}</h5>

                                                            </div>
                                                            <div class="modal-body">
                                                               <div class="row" >
                                                                   <div class="col-md-6 Details">
                                                                       <h5>Merchant Details</h5>
                                                                       <p>{{orderView.store_name}}</p>
                                                                       <p>{{orderView.pickup_location}}</p>
<!--                                                                       <p>60329,FrankFurt</p>-->
<!--                                                                       <p>09090877772232</p>-->
                                                                   </div>
                                                                   <div class="col-md-6 Details">
                                                                       <h5>Client Details</h5>
                                                                       <p>{{delivoryAdd.name}}</p>
                                                                       <p>{{delivoryAdd.address_line}}</p>
                                                                       <p>{{delivoryAdd.postal_code}},{{delivoryAdd.city}}</p>
                                                                       <p>{{delivoryAdd.mobile_number}}</p>
                                                                   </div>
                                                                   <div class="col-md-6 Details">
                                                                       <h5 style="margin-top: 2rem;">Payment Method:</h5>
                                                                       <p>{{orderView.payment_type}}</p>
                                                                       <p>{{orderView.order_type}} <i v-if="orderView.order_type !== 'takeaway'" class="fa fa-car" aria-hidden="true"></i></p>

                                                                   </div>
                                                                   <div class="col-md-6 Details">
                                                                       <h5 style="margin-top: 2rem;">Order Details</h5>
                                                                       <p>Order Place Date:- {{orderStatustimes.order_placed_time}}</p>
                                                                       <p>{{orderView.store_name}}</p>
                                                                       <p>{{orderView.order}}</p>
                                                                       <p>Delivory Date:- {{orderStatustimes.delivery}}</p>

                                                                   </div>
                                                                   <div>
                                                                       <h5 style="margin-top: 2rem;">Order summary</h5>
                                                                       <hr>
                                                                       <div  class="row">
                                                                        <div class="col-md-12"  v-for="(data,ind) in orderItemsDetail" :key="ind" style="padding:0.5rem 2rem 0.5rem 2rem;position:relative;">
                                                                               <span>{{data.quantity}} x {{ data.name}}</span><br>
                                                                               <span v-for="(itm,ind) in data.modifiers" :key ="ind">
                                                                                <span style="font-size:13px;padding-left:8px;">{{itm.count}} x {{itm.price}} {{ itm.name }} </span><br>
                                                                                <!-- <span>{{ itm.price }}</span> -->
                                                                                
                                                                               </span>
                                                                               <span style="position: absolute;right: 46px;top: 9px;" >€ {{ data.price }}</span>
                                                                        </div>
                                                                          
                                                                        

                                                                       </div>
                                                                       <hr>
                                                                       <table style="width:100%;margin-top:1rem;">
                                                                       <tbody>
                                                                           <tr>
                                                                               <td style="width:198px;"></td>

                                                                               <td col-span-2>
                                                                                   <b><span>Sub Total</span></b>
                                                                               </td>
                                                                               <td>
                                                                                   <span>€ {{orderView.subtotal}}</span>
                                                                               </td>
                                                                           </tr>
                                                                           <tr>
                                                                               <td style="width:198px;"></td>

                                                                               <td>
                                                                                   <b><span>Delivory fee(+)</span></b>
                                                                               </td>
                                                                               <td>
                                                                                   <span>€ {{orderView.delivery_fee}}</span>
                                                                               </td>
                                                                           </tr>
                                                                           <tr >
                                                                               <td style="width:198px;"></td>

                                                                               <td>
                                                                                   <b><span>Total</span></b>
                                                                               </td>
                                                                               <td>
                                                                                   <span>€ {{orderView.total}}</span>
                                                                               </td>
                                                                           </tr>

                                                                           </tbody>
                                                                       </table>
                                                                   </div>
                                                               </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
<!--                                                                <button type="button" class="btn btn-primary">Save changes</button>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" class="btn  cardBtn" data-toggle="modal" :data-target="('#exampleModalLong1'+ ind)" @click="orderHistoryApi(data.order_no)" style="background:saddlebrown;color:#fff">History</button>
                                                <!-- Modal -->
                                                <div class="modal fade" :id="('exampleModalLong1'+ ind)" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                                    <div class="modal-dialog" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLongTitle">History</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <table>

                                                                    <tbody>

                                                                    <tr v-if="orderHistoryList.order_placed_time !==''">
                                                                        <td><b>Order placed time</b></td>
                                                                        <td>{{orderHistoryList.order_placed_time}}</td>
                                                                    </tr>
                                                                    <tr v-if="orderHistoryList.store_accepted_time !==''">
                                                                        <td><b>Store accepted time</b></td>
                                                                        <td>{{orderHistoryList.store_accepted_time}}</td>
                                                                    </tr>
                                                                    <tr v-if="orderHistoryList.order_delivery_time !==''">
                                                                        <td><b>Order delivery time</b></td>
                                                                        <td>{{orderHistoryList.order_delivery_time}}</td>
                                                                    </tr>
                                                                    <tr v-if="orderHistoryList.order_completed_time !==''">
                                                                        <td><b>Order completed time</b></td>
                                                                        <td>{{orderHistoryList.order_completed_time}}</td>
                                                                    </tr>

                                                                    <tr v-if="orderHistoryList.driver_confirmed_time !==''">
                                                                        <td><b>Deliver confirmed time</b></td>
                                                                        <td>{{orderHistoryList.driver_confirmed_time}}</td>
                                                                    </tr>
                                                                    <tr v-if="orderHistoryList.driver_confirmed_time !==''">
                                                                        <td><b>Deliver trip start time</b></td>
                                                                        <td>{{orderHistoryList.driver_confirmed_time}}</td>
                                                                    </tr>

                                                                    <tr v-if="orderHistoryList.order_cancelled_time !==''">
                                                                        <td><b>Order cancelled time</b></td>
                                                                        <td>{{orderHistoryList.order_cancelled_time}}</td>
                                                                    </tr>
                                                                    <tr v-if="orderHistoryList.order_declined_time !==''">
                                                                        <td><b>Order declined time</b></td>
                                                                        <td>{{orderHistoryList.order_declined_time}}</td>
                                                                    </tr>

                                                                    </tbody>
                                                                </table>



                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <button v-if="Value !=='completed'" type="button" class="btn  cardBtn" data-toggle="modal" data-target="#exampleModalCenter2"  style="background:darkslateblue;color:#fff">Remark</button>
                                                <!-- Modal -->
                                                <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLongTitle">Change Order Status</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">

                                                                <label>Remark</label><br>
                                                                <textarea v-model="RemarkText" style="width:100%;resize: none"></textarea><br>
<!--                                                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">-->
<!--                                                                <label style="margin-left: 0.5rem" for="vehicle1">Is For Driver?</label><br>-->


                                                            </div>
                                                            <div class="modal-footer">

                                                                <button type="button" class="btn btn-primary close" data-dismiss="modal" aria-label="Close" @click="addDriverNoteApi(data.order_no)">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div  style="padding:1rem;align-items:center;text-align:center;">
                                                <button v-if="((data.status =='accepted' || data.status =='assigned') && (Value =='accepted' || Value =='ExceedTime')) " type="button" class="btn btn-danger cardBtn" data-toggle="modal" :data-target="('#exampleModalCenter3'+ ind)" @click='this.driverCity=data.city'>Assign</button>
                                                <!-- Modal -->
                                                <div   class="modal fade" :id="('exampleModalCenter3'+ ind)" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLongTitle">Task ID : {{data.order_no}}</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div v-if="(driverListWithCity && driverListWithCity[driverCity] )|| this.driverListWithCity[this.driverCity] ">
                                                            <div class="modal-body" style="text-align: left;" >
                                                                <label>Select Team</label><br>

                                                                 <p v-if='driverListWithCity[driverCity].length ===  0' style='margin:2rem auto;'>There is no any driver Yet...</p>


                                                                <select v-else class="form-select" v-model="SelecteddriverId" aria-label="Default select example" style="margin-bottom: 1rem;">

                                                                    <option v-for="(data,ind) in driverListWithCity[driverCity]" :key="ind" :value="data.driver_id" >{{data.name}} ,( {{data.assignee_city}},{{data.driver_id}}) </option>


                                                                </select>
                                                                <span class="values">Assiugn Agent (Last Known Locations)</span>



                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-danger close" data-dismiss="modal" aria-label="Close">Cancle</button>
                                                                <button type="button" class="btn btn-primary close" data-dismiss="modal" aria-label="Close" @click="assignToDriverApi(data.order_no,data.status)">Submit</button>
                                                            </div>
                                                            </div>
                                                            <div v-else style="padding: 2rem;"> There are currently no drivers available in this city.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                          
                                <div v-if="!this.serData" style="display: flex;padding:1rem;position:absolute;bottom:0">
                                    <button class="btn btn-primary" @click="previousPage"> Previous</button>

                                    <button class="btn btn-primary" style="margin-left: 0.5rem;" @click="nextPage">Next </button>
                                    <span style="padding:0.5rem;">current page:- {{ currentPage }} / total page:- {{ totalPages }}</span>
                                </div>



                            </div>
                        </div>

                    </div>
                    <div class="col-md-3">
                        <span class="heads">Drivers</span>
                        <div class="container mainSec2">
                            <div class="row UpdatesSec hideScroll" style="height:487px;overflow-y:scroll;">
                                <div class="col-md-12 Updates">
                                    <span class="heads">Total Free Driver (<span class="values">{{this.driverData.length}}</span>)</span><br>



                                    <ul v-for="(data,indx) in  driverData" :key="indx">
                                        <li class="drivers">
                                            <span><i class="fa fa-car" aria-hidden="true"></i> {{ data.name}}( {{data.assignee_city}} , {{ data.driver_id }})</span>
                                        </li>
                                        </ul>

                                </div>

                            </div>




                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>




</template>

<script>
    import axios from 'axios';
    import _ from 'lodash'
    import swal from 'sweetalert';
    
     //BaseUrl
    import { BASE_URL } from '@/mixin/api';


    export default {
        name: 'DashboardView',

        data(){
            return{
                Value:'',
                changeOrderStatusValue:'',
                getOrderData:[],
                getOrderDataKEy:[],
                getAllArr:[],
                totalOrderCount:0,
                pangingOrderCount:0,
                assignOrderCount:0,
                successfulOrderCount:0,
                freeDriver:[],
                driverData:[],
                SelecteddriverId:'',
                orderData:[],
                selectedKey:'',
                getdriverKEy:[],
                selectedDriverKey:'',
                count:0,
                allOrderStatus:null,
                serData:'',
                Assigncity:'',
                currentPage: 1,
                itemsPerPage: 9,
                orderHistoryList:[],
                orderView:[],
                delivoryAdd:[],
                orderStatustimes:[],
                orderItemsDetail:[],
                Settimes:0,
                OrderStatus:'',
                RemarkText:'',
                loclstoreCount:0,
                OrderNoArray:[],
                SelectMErchantType:'Close Holiday Merchant',
                isLoading: false,
                closeMerchantKeyVal:[],
                holidayMerchantKeyVal:[],
                holidayMerchantDatalist:[],
                getpendingOrderData:[],
               
                pendingorderData:[],

                closeMerchantDatalist:[],
                displayOrdersData:[],
                driverListWithCity:[],
                driverCity:'',
                setCount:0,





            }
        },
        // beforeCreate(){
        //     let value = localStorage.status;
        //     console.log(value);
        //     if(value === "1"){
        //         this.$router.push({path:'/home'})
        //     }
        //     else{
        //         this.$router.push({path:'/'})
        //     }
        //
        //
        // },
        created() {
            if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
    setInterval(() => {
        
       
        if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
            this.getHealthStatus();
            
       
    }, 60000); 
    setInterval(() => {
   
     if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
            
            this.getFreeDrivers();
          
    }, 30000); 
   
        setInterval(() => {
   
   if(this.driverListWithCity[this.driverCity]==undefined){
      this.driverListWithCity[this.driverCity]='';
          }
          if(this.setCount==1){   
       this.pandingOrderAoiAutoCal();
          }
        
  }, 30000);
  
  },
 
        mounted() {

                   
            localStorage.setItem('title', 'Admin dashboard');
            const storedTitle = localStorage.getItem('title');
            if (storedTitle) {
      // Update the document title with the stored title
      document.title = storedTitle;
    }
         
          this.Assigncity=localStorage.Assignee_city;
        
           this.closeHolidayMarchant();
           this.AllOrderApiCall();
           this.$refs.autoClick.click();
           this.getAllOrderStatusapiCall('ALL');
        },
        computed:{

            totalPages() {
                return Math.ceil(this.displayOrdersData.length / this.itemsPerPage);
            },
            displayedItems() {


                if (!this.serData) {
                    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                    const endIndex = startIndex + this.itemsPerPage;
                    return this.displayOrdersData.slice(startIndex, endIndex);
                }
                return this.displayOrdersData.filter(item => {
                    const booking = item.order_no.toString().includes(this.serData);
                    const storename = item.store_name.toLowerCase().includes(this.serData.toLowerCase());
                    console.log(booking);

                    if(storename){
                        return item.store_name.toLowerCase().includes(this.serData.toLowerCase());
                    }
                    if(booking){
                        return item.order_no.toString().includes(this.serData);
                    }

                })
            },
        },

        methods:{
            async closeHolidayMarchant(){
                axios.get(''+BASE_URL+'/admin_api/get_close_holiday_store?token='+localStorage.token+'&city='+this.selectedDriverKey+'').then( resp =>{
                    const closeMerchantData = resp.data.closed_store;
                    const holiday_Store = resp.data.holiday_store;
                    
                    Object.values(closeMerchantData).forEach(val => this.closeMerchantKeyVal.push(val));
                  
             
                     for (let i = 0; i < this.closeMerchantKeyVal.length; i++) {
                        for (let j = 0; j < this.closeMerchantKeyVal[i].length; j++) {
                            console.log(this.closeMerchantKeyVal[i][j].status)
                           
                           
                                this.closeMerchantDatalist.push(this.closeMerchantKeyVal[i][j]);
                         
                               

                          
                           

                        }
                      }
                      Object.values(holiday_Store).forEach(val => this.holidayMerchantKeyVal.push(val));
                    for (let k = 0; k < this.holidayMerchantKeyVal.length; k++) {
                        for (let l = 0; l < this.holidayMerchantKeyVal[k].length; l++) {
                            console.log(this.holidayMerchantKeyVal[k][l].status)
                           
                           
                                this.holidayMerchantDatalist.push(this.holidayMerchantKeyVal[k][l]);
                         
                               

                          
                           

                        }
                    }
            

                    console.log('cm',this.closeMerchantDatalist)
                });
            },
            addDriverNoteApi(bookingId){
                axios.get(''+BASE_URL+'/admin_api/add_driver_note?token='+localStorage.token+'&order_id='+bookingId+'&driver_notes='+this.RemarkText+'').then( resp =>{
                    var assigned = resp.data.status_message;
                    swal(assigned," ", "");




                });

            },
  AcceptedValue(value){
    this.setCount = 0;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
  this.currentPage =1;
   this.displayOrdersData=[]
     this.Value = value
     this.getAllOrderStatusapiCall(this.Value);

 
  },
  AssignedValue(value){
    this.setCount = 0;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
    this.currentPage =1;
    this.displayOrdersData=[]
     this.Value = value
     this.getAllOrderStatusapiCall(this.Value);
    
 
 
  },
  SelfOrder(value){
    this.setCount = 0;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
    this.currentPage =1;
    this.displayOrdersData=[]
     this.Value = value
     
    //  this.apiFunc()
 
  },
  pendingValue(value){
    this.setCount = 1;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
    this.currentPage =1;
  
     this.Value = value
     console.log( this.Value)
     this.apiFunc()
  
  },
  ExceedTimeValue(value){
    this.setCount = 0;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
    this.currentPage =1;
    this.displayOrdersData=[]
    this.Value = value
    this.getAllOrderStatusapiCall(this.Value);
    //  this.apiFunc()
  },

  DeclinedValue(value){
    this.setCount = 0;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
    this.currentPage =1;
    this.displayOrdersData=[]
    this.Value = value
    this.getAllOrderStatusapiCall(this.Value);
    //  this.apiFunc()
  },
  SucessValue(value){
    this.setCount = 0;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
    this.currentPage =1;
    this.displayOrdersData=[]
    this.Value = value
    this.getAllOrderStatusapiCall(this.Value);
    //  this.apiFunc()
  },
  preeOrderValur(value){
    this.setCount = 0;
    if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
this.displayOrdersData=[]        
  this.orderData =  _.cloneDeep(JSON.parse(localStorage.AllOrderList))
    this.currentPage =1;
    this.Value = value
    this.orderData.forEach((obj) => {
    
    if (  this.Value === obj.status) {
    
      this.displayOrdersData.push(obj)
    }
    
  });

    //  this.apiFunc()
  },
  ChValur(value){
    this.setCount = 0;
    this.Value = value
    
  },
            assignToDriverApi(bookingId,status){
                // this.apiFunc()
                this.driverData = []
                var bookingid = bookingId;
                this.isLoading=true;
                axios.get(''+BASE_URL+'/admin_api/assign_driver?token='+localStorage.token+'&order_id='+bookingid+'&driver_id='+this.SelecteddriverId+'').then( resp =>{
                    var assigned = resp.data.status_message;
                    if(resp.data.status_message == 'Order accepted'){
                        const Arr = JSON.parse(localStorage.AllOrderList)
                        const Index = Arr.findIndex(data => data.order_no === bookingid);
                        console.log("Index",Index)

                        Arr[Index].status = status;
                        
                        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
                       
                    }
                    this.getAllOrderStatusapiCall(status);
                    this.isLoading=false;
                   swal(assigned," ", "");
                  



                });
            },

            orderHistoryApi(bookingId) {
               
                var bookingid = bookingId;
                this.isLoading=true;
                console.log(bookingid)
                axios.get(''+BASE_URL+'/admin_api/order_history?token='+localStorage.token+'&order_id='+bookingid+'').then( resp =>{
                    this.orderHistoryList = resp.data.order_history;
                    this.isLoading= false;
                    console.log(this.orderHistoryList);
                   
                });
               
            },
            orderViewApi(bookingId) {
                this.isLoading=true;
                var bookingid = bookingId;
                console.log(this.isLoading)
              
                axios.get(''+BASE_URL+'/admin_api/order_details?token='+localStorage.token+'&order_id='+bookingid+'').then( resp =>{
                    var orderViews = resp.data.order_details;
                    this.isLoading=false;
                    this.delivoryAdd = resp.data.order_details.delivery_address;
                    this.orderStatustimes = resp.data.order_details.status_times;
                    this.orderItemsDetail = resp.data.order_details.item_details;
                    console.log("Item Details",this.orderItemsDetail);
                    this.orderView =_.cloneDeep(orderViews);
                    console.log("orderItemsDetail",this.orderItemsDetail);
                   
                });
             
            },
            getButtonValue(Settimes){
                if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
                this.Settimes = Settimes;
                console.log(this.Settimes);

            },
            declinedOrder(value,status,bookingid){
            let text = "Are you sure";
              if (confirm(text) == true) {
              this.ChangeOrderStatusApi(value,status,bookingid)

              } else {
             text = "You canceled!";
             }
                },
 ChangeOrderStatusApi(value,status,bookingid){
                this.isLoading= true;

axios.get(''+BASE_URL+'/admin_api/accept_order_status?token='+localStorage.token+'&order_id='+bookingid+'&change_status='+value+'&preparation_time='+this.Settimes+'').then( resp =>{
    var orderstatus = resp.data;
    if(resp.data.status_message == 'Order accepted'){
        const Arr = JSON.parse(localStorage.AllOrderList)
        const Index = Arr.findIndex(data => data.order_no === bookingid);
        console.log("Index",Index)

        Arr[Index].status = status;
        
        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
        swal(resp.data.status_message," ", "");
        this.apiFunc();
    }
    if(resp.data.status_message == 'Order Cancelled'){
        
         swal(resp.data.status_message," ", "");
        const Arr = JSON.parse(localStorage.AllOrderList)
        const Index = Arr.findIndex(data => data.order_no === bookingid);
        console.log("Index",Index)

        Arr[Index].status = 'declined';
        
        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
     
        this.apiFunc();
        }
        if(resp.data.status_message == 'Order completed'){
         swal(resp.data.status_message," ", "");
        const Arr = JSON.parse(localStorage.AllOrderList)
        const Index = Arr.findIndex(data => data.order_no === bookingid);
        console.log("Index",Index)

        Arr[Index].status = 'declined';
        
        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
     
        this.apiFunc();
        }
      

    
    this.isLoading= false;

    console.log(orderstatus);


});
              
             

            },
            changeAcceptedOrderStatus(status,bookingid){
                this.apiFunc()
                console.log("status",status,bookingid);
                const time=0
                axios.get(''+BASE_URL+'/admin_api/change_order_status?token='+localStorage.token+'&order_id='+bookingid+'&change_status='+status+'&preparation_time='+time+'').then( resp =>{
                    this.isLoading= false;
                    swal(resp.data.status_message," ", "");
                   


                });

            }
            ,

            previousPage() {
                if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
                if (this.currentPage > 1) {
                    this.currentPage -= 1;
                }
            },
            nextPage() {
                if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
                if (this.currentPage < this.totalPages) {
                    this.currentPage += 1;
                }
            },

             apiCalls(city){
                 console.log(city);
                this.OrderNoArray=[];
        
               

           this.closeHolidayMarchant();
           this.AllOrderApiCall();
           this.$refs.autoClick.click();
           this.getAllOrderStatusapiCall('ALL');

            },
            async getHealthStatus(){
                this.totalOrderCount=0;
                this.pangingOrderCount=0;
                this.assignOrderCount=0;
                this.successfulOrderCount=0;
                const today = new Date().toISOString().substr(0, 10);
                await axios.get(''+BASE_URL+'/admin_api/get_health_stats?token='+localStorage.token+'&city='+this.selectedDriverKey+'&from_date='+today+'&to_date='+today+'').then(resp =>{
                    const allOrderStatus  = resp.data.order_stats;
                    console.log("allOrderStatus",allOrderStatus);


                    for(let key in allOrderStatus){

                        //console.log('BBBBBBBBBBBBBB',allOrderStatus[key][0].assigned_order);
                        if(allOrderStatus[key][0].assigned_order==''){
                            allOrderStatus[key][0].assigned_order=0;
                        }
                        this.totalOrderCount = this.totalOrderCount + parseInt(allOrderStatus[key][0].total_order);
                        this.pangingOrderCount = this.pangingOrderCount + parseInt(allOrderStatus[key][0].pending_order);
                        this.assignOrderCount = this.assignOrderCount + parseInt(allOrderStatus[key][0].assigned_order);
                        this.successfulOrderCount = this.successfulOrderCount + parseInt(allOrderStatus[key][0].successful_order);
                    }


                })


            },
 async getFreeDrivers(){
    this.driverData=[];
                this.freeDriver=[];        
    await axios.get(''+BASE_URL+'/admin_api/free_driver_list?token='+localStorage.token+'&city='+this.selectedDriverKey+'').then(resp => {

let freeDRi = resp.data.driver_list;
// console.log(resp.data.driver_list);
if(this.count === 0){

    this.getdriverKEy = Object.keys(freeDRi);
}
this.count=1;
console.log('this---',freeDRi)
this.driverListWithCity = _.cloneDeep(freeDRi);
Object.values(freeDRi).forEach(val => this.freeDriver.push(val));
console.log('nhnhn',this.freeDriver);

for (let i = 0; i < this.freeDriver.length; i++) {
    for (let j = 0; j < this.freeDriver[i].length; j++) {
        this.driverData.push(this.freeDriver[i][j]);
    }
}



});

                      
            },
            async apiFunc(){
                this.driverData=[];
                this.freeDriver=[];
               
                // this.getOrderData=[];
                this.displayOrdersData=[]
                this.totalOrderCount=0;
                this.pangingOrderCount=0;
                this.assignOrderCount=0;
                this.successfulOrderCount=0;
                this.isLoading=true;
              
                const today = new Date().toISOString().substr(0, 10);
                await axios.get(''+BASE_URL+'/admin_api/get_health_stats?token='+localStorage.token+'&city='+this.selectedDriverKey+'&from_date='+today+'&to_date='+today+'').then(resp =>{
                    const allOrderStatus  = resp.data.order_stats;
                    console.log("allOrderStatus",allOrderStatus);


                    for(let key in allOrderStatus){

                        console.log('AAAAAAAAAAAAA',allOrderStatus[key][0].assigned_order);
                        if(allOrderStatus[key][0].assigned_order==''){
                            allOrderStatus[key][0].assigned_order=0;
                        }
                        this.totalOrderCount = this.totalOrderCount + parseInt(allOrderStatus[key][0].total_order);
                        this.pangingOrderCount = this.pangingOrderCount + parseInt(allOrderStatus[key][0].pending_order);
                        this.assignOrderCount = this.assignOrderCount + parseInt(allOrderStatus[key][0].assigned_order);
                        this.successfulOrderCount = this.successfulOrderCount + parseInt(allOrderStatus[key][0].successful_order);
                    }


                })

               
               await axios.get(''+BASE_URL+'/admin_api/free_driver_list?token='+localStorage.token+'&city='+this.selectedDriverKey+'').then(resp => {

let freeDRi = resp.data.driver_list;
// console.log(resp.data.driver_list);
if(this.count === 0){

    this.getdriverKEy = Object.keys(freeDRi);
}
this.count=1;
console.log('this---',freeDRi)
this.driverListWithCity = _.cloneDeep(freeDRi);
Object.values(freeDRi).forEach(val => this.freeDriver.push(val));
console.log('nhnhn',this.freeDriver);

for (let i = 0; i < this.freeDriver.length; i++) {
    for (let j = 0; j < this.freeDriver[i].length; j++) {
        this.driverData.push(this.freeDriver[i][j]);
    }
}



});

               
         
                     this.pendingorderData=[]
                     this.getpendingOrderData=[]
                 this.orderData =  _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                 await axios.get(''+BASE_URL+'/admin_api/pending_order_list?token='+localStorage.token+'&city='+this.selectedDriverKey+'').then(resp =>{
                    
                     const  pendingorderData = resp.data.pending_order_list;
                     console.log("pd",pendingorderData)
                     if(pendingorderData.length== 0){
                        this.displayOrdersData = [];
                        return false;
                     }
                   
                     this.getpendingOrderDataKEy = Object.keys(pendingorderData);
                     this.getpendingOrderDataKEy.forEach(item => {
      if (  this.getOrderDataKEy.includes(item)) {
      
        return false;// ignore duplicate item
      } else {
        this.getOrderDataKEy.push(item);
        
      }});

                     7// print all keys

                     Object.values(pendingorderData).forEach(val => this.getpendingOrderData.push(val));

for (let i = 0; i < this.getpendingOrderData.length; i++) {
    for (let j = 0; j < this.getpendingOrderData[i].length; j++) {
        console.log(this.getpendingOrderData[i][j].status)


        this.pendingorderData.push(this.getpendingOrderData[i][j]);
        // Two arrays to compare

       }}

this.getAllOrderStatusapiCall(this.Value);

if(pendingorderData.length !==0){
  
console.log("Heyiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",this.orderData)
this.displayOrdersData=_.cloneDeep(this.pendingorderData);
this.pendingorderData.forEach((elem) => {
  let index =   this.orderData.findIndex((e) => e.order_no === elem.order_no);
  console.log("index",index)

  // If the element is found in the second array, compare the values
  if (index !== -1) {
    if (this.orderData[index].order_no !== elem.order_no) {
        console.log("1...")
        this.orderData.push(elem);
    }
  } else {
    // If the element is not found in the second array, push it into the new array
    console.log("2...")
    this.orderData.push(elem);
  }
 
 

});
console.log("HEy2",this.orderData)

localStorage.setItem('AllOrderList', JSON.stringify(this.orderData))
this.orderData =  _.cloneDeep(JSON.parse(localStorage.AllOrderList))
// localStorage.AllOrderList =_.cloneDeep(JSON.stringify(this.orderData))
// this.displayOrdersData=[]
this.OrderNoArray = []
JSON.parse(localStorage.AllOrderList).forEach((data)=> this.OrderNoArray.push(data.order_no))


// localStorage.setItem('AllOrderList', JSON.stringify(this.orderData))
// localStorage.setItem('AllOrderList', JSON.stringify(this.orderData))


console.log( "mainnnnnnn",JSON.parse(localStorage.AllOrderList));



                    
                        
                     }



                 });

                 this.isLoading=false;

             },
           
            logOutFunc(){
                let text = "Are you sure !";
         if (confirm(text) == true) {
            this.$router.push({path:'/'})
      localStorage.status = null;
       localStorage.token = null;
      localStorage.Assignee_city = null;
              } else {
             text = "You canceled!";
             }
   


                
            },
            async AllOrderApiCall(){
                this.orderData=[];
                this.getOrderData=[];
                await axios.get(''+BASE_URL+'/admin_api/get_all_today_orders?token='+localStorage.token+'&city='+this.selectedDriverKey+'').then(resp =>{
                 
                 const orderData = resp.data.order_details;
                // console.log("pd",orderData)
                 this.isLoading=false;
                 this.getOrderDataKEy = Object.keys(orderData);
                //  console.log("city",this.getOrderDataKEy);

                 7// print all keys

                 Object.values(orderData).forEach(val => this.getOrderData.push(val));
                
                 for (let i = 0; i < this.getOrderData.length; i++) {
                     for (let j = 0; j < this.getOrderData[i].length; j++) {
                        //  console.log(this.getOrderData[i][j].status)


                         this.orderData.push(this.getOrderData[i][j]);
                         // if(this.getOrderData[i][j].status === this.Value){
                         //     this.orderData.push(this.getOrderData[i][j]);
                         // }
                         // if(this.getOrderData[i][j].order_type === this.Value){
                         //     this.orderData.push(this.getOrderData[i][j]);
                         // }
                         // if(this.getOrderData[i][j].delivery_type === this.Value){
                         //     this.orderData.push(this.getOrderData[i][j]);
                         // }
                            

                       
                        

                     }
                 }
             
                
          
             


                     localStorage.setItem('AllOrderList', JSON.stringify(this.orderData))

                     this.orderData.forEach((data)=> this.OrderNoArray.push(data.order_no))
                     this.orderData =  _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                     this.displayOrdersData = [];
                     this.orderData.forEach((obj) => {
    
                             if (this.Value === obj.status) {
    
                            this.displayOrdersData.push(obj)
                                     }
    
                                });
               
                    //  console.log("orderId",this.orderData)
   
                //  console.log("orderId",this.OrderNoArray)
               

             });
             
            },
            async pandingOrderAoiAutoCal(){
                if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }this.pendingorderData=[]
                     this.getpendingOrderData=[]
               
                this.orderData =  _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                 await axios.get(''+BASE_URL+'/admin_api/pending_order_list?token='+localStorage.token+'&city='+this.selectedDriverKey+'').then(resp =>{
                     
                     const  pendingorderData = resp.data.pending_order_list;
                    //  console.log("pd",pendingorderData)
                     if(pendingorderData.length == 0){
                        this.displayOrdersData = [];
                        return false;
                     }
                  
                     this.getpendingOrderDataKEy = Object.keys(pendingorderData);
                     this.getpendingOrderDataKEy.forEach(item => {
      if (  this.getOrderDataKEy.includes(item)) {
      
        return false;// ignore duplicate item
      } else {
        this.getOrderDataKEy.push(item);
        
      }});

                     7// print all keys

 Object.values(pendingorderData).forEach(val => this.getpendingOrderData.push(val));

for (let i = 0; i < this.getpendingOrderData.length; i++) {
    for (let j = 0; j < this.getpendingOrderData[i].length; j++) {
        // console.log(this.getpendingOrderData[i][j].status)
        this.pendingorderData.push(this.getpendingOrderData[i][j]);
        // Two arrays to compare

       }}
 
// this.getAllOrderStatusapiCall(this.Value);

if(pendingorderData.length !==0){
  
console.log("Hey",this.orderData)

this.pendingorderData.forEach((elem) => {
  let index =   this.orderData.findIndex((e) => e.order_no === elem.order_no);
//   console.log("index",index)

  // If the element is found in the second array, compare the values
  if (index !== -1) {
    if (this.orderData[index].order_no !== elem.order_no) {
        // console.log("1...")
        this.orderData.push(elem);
    }
  } else {
    // If the element is not found in the second array, push it into the new array
    // console.log("2...")
    this.orderData.push(elem);
  }
 
 

});
// console.log("HEy2",this.orderData)

localStorage.setItem('AllOrderList', JSON.stringify(this.orderData))
this.orderData =  _.cloneDeep(JSON.parse(localStorage.AllOrderList))
// localStorage.AllOrderList =_.cloneDeep(JSON.stringify(this.orderData))
this.displayOrdersData=[]
this.OrderNoArray = []
JSON.parse(localStorage.AllOrderList).forEach((data)=> this.OrderNoArray.push(data.order_no))
this.displayOrdersData=_.cloneDeep(this.pendingorderData);

// localStorage.setItem('AllOrderList', JSON.stringify(this.orderData))
// localStorage.setItem('AllOrderList', JSON.stringify(this.orderData))


// console.log( "mainnnnnnn",JSON.parse(localStorage.AllOrderList));



                    
                        
                     }



                 });

               

            },
         
            async getAllOrderStatusapiCall(status){
                if(this.driverListWithCity[this.driverCity]==undefined){
        this.driverListWithCity[this.driverCity]='';
            }
                this.isLoading = true;
                 var orderNO = this.OrderNoArray.join(",");
                // console.log(orderNO,status);
                await axios.get(''+BASE_URL+'/admin_api/get_order_status?token='+localStorage.token+'&order_id='+orderNO+'').then(resp =>{
               
                    if(status == 'All'){
                        const data = resp.data.order_details;
                        console.log(data)
                    }
                  
                  else if(status == 'accepted'){
                        const data = resp.data.order_details.accepted;
                        this.displayOrdersData = [];
                        const Arr = JSON.parse(localStorage.AllOrderList)
                        data.forEach(itm => {
                            
                        const Index = Arr.findIndex(sm => sm.order_no === itm);
                        console.log("Index",Index)

                        Arr[Index].status = status;
                        
                       
                        });
                        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
                        const FilteredArr = _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                        FilteredArr.forEach(item2 => {
                        data.forEach(item1 => {
                        if(item1 === item2.order_no){
                            this.displayOrdersData.push(item2);
                        }
                    });

}); this.isLoading = false;  

                    }
                    else if(status == 'declined'){
                        const data = resp.data.order_details.cancelled; 
                        this.displayOrdersData = [];
                        const Arr = JSON.parse(localStorage.AllOrderList)
                       
                     
                        data.forEach(itm => {
                            
                        const Index = Arr.findIndex(sm => sm.order_no === itm);
                        console.log("Index",Index)

                        Arr[Index].status = status;
                        
                       
                        });
                        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
                        const FilteredArr = _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                        FilteredArr.forEach(item2 => {
                        data.forEach(item1 => {
                        if(item1 === item2.order_no){
                            this.displayOrdersData.push(item2);
                        }
                    });
});this.isLoading = false;
                   
this.isLoading = false;  
}
                    else if(status == 'assigned'){
                        const data = resp.data.order_details.assigned; 
                        this.displayOrdersData = [];
                        const Arr = JSON.parse(localStorage.AllOrderList)
                        data.forEach(itm => {
                            
                        const Index = Arr.findIndex(sm => sm.order_no === itm);
                        console.log("Index",Index)

                        Arr[Index].status = status;
                        
                       
                        });
                        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
                        const FilteredArr = _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                        FilteredArr.forEach(item2 => {
                        data.forEach(item1 => {
                        if(item1 === item2.order_no){
                            this.displayOrdersData.push(item2);
                        }
                    });
});this.isLoading = false;
                    

}
                    else if(status == 'completed'){
                        const data = resp.data.order_details.successful; 
                        this.displayOrdersData = [];
                        const Arr = JSON.parse(localStorage.AllOrderList)
                        data.forEach(itm => {
                            
                        const Index = Arr.findIndex(sm => sm.order_no === itm);
                        console.log("Index",Index)

                        Arr[Index].status = status;
                        
                       
                        });
                        localStorage.setItem('AllOrderList', JSON.stringify(Arr))
                        const FilteredArr = _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                        FilteredArr.forEach(item2 => {
                        data.forEach(item1 => {
                        if(item1 === item2.order_no){
                            this.displayOrdersData.push(item2);
                        }
                    });
                    this.isLoading = false;  
});
                    }
                    else if(status == 'ExceedTime'){
                        const data = resp.data.order_details.exceeded_time; 
                        this.displayOrdersData = [];
                       
                       
                        const FilteredArr = _.cloneDeep(JSON.parse(localStorage.AllOrderList))
                        FilteredArr.forEach(item2 => {
                        data.forEach(item1 => {
                        if(item1 === item2.order_no){
                            this.displayOrdersData.push(item2);
                        }
                    });this.isLoading = false;  
});
                    }
                  
              
                                       
console.log("respsss",this.displayOrdersData);

                 }).catch(error => {
        console.log(error)
        this.isLoading = false; 

      })
                
            }


        },


        props: {
            msg: String
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    p{
        font-size: 13px;
    }
    .buttonSec{
       

    }
    .mainSec{
        padding:1rem;
        text-align: left;
        background: #fbf7f7;
        height:100Vh;

    }
    .mainSec1{
        margin-top:1rem;
        background: #fff;

    }
    .values{
        font-size:22px;
        font-weight:600;
        color:#6e6d6d;
    }
    .drivers{
        padding:0.5rem;
        margin-top:0.5rem;
        background: #fbf7f7;
        list-style: none;
    }
    .heads{
        font-size: 18px;
        font-weight:400;

    }
    ul{
        padding-left:0rem;
    }
    .mainSec2{

        padding:1rem;
        margin-top:1rem;
        background: #fff;

    }
    .Updates{
        padding:1rem 1rem;
    }
    .Updatesborder{
        border-right:1px solid #222;

    }
    .UpdatesSec{
        border:1px solid #333;
        border-radius: 20px;

    }
    .btn-primary{
        margin-right:0.2rem;
        font-size: 13px;
    }
    .cardBtn{
        camcmargin: 1.5px;
        width: 73px;
        font-size: 12px;
    }
    .btn-danger{
        margin-right:0.2rem;
        font-size: 13px;
    }
    .btn-success{

        font-size: 11px;
    }
    form.example input[type=text] {
        padding: 10px;
        font-size: 17px;
        border: 1px solid grey;
        float: left;
        width: 100%;
        border-radius: 12px;


    }
    form{
        margin:2rem auto;

    }

    form.example button {
        float: left;
        width: 20%;
        padding: 10px;
        background: #0d6efd;
        color: white;
        font-size: 17px;
        border: 1px solid grey;
        border-left: none;
        cursor: pointer;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    form.example button:hover {
        background: #0b7dda;
    }

    form.example::after {
        content: "";
        clear: both;
        display: table;
    }
    td{
        padding:0.5rem 1.5rem;
        border-bottom: 1px solid black;
    }
    .Details p{
        margin:0;
    }
    .timesBtn{
        width:30%;
        margin-left: 0.6rem;
        margin-top:1rem;
        margin-bottom: 1rem;
    }
    .btnActive{
     background: green !important;
    }
    .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
table {
    border-collapse: collapse;
}
.table-striped tbody tr {
    background:#fbf7f7;
}
.tbody tr th{
    border-top: 1px solid #dee2e6;
}
.table td, .table th {
    vertical-align: middle;
}

.table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
th {
    text-align: inherit;
}
tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
main {
    display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100vh;
background-color: #0b0b0bba;
position: absolute;
z-index:99999;
}

.loader {
  --loader-size: 50px;
  --loader-border-size: 4px;
  --loader-border-color: white;
  width: var(--loader-size);
  height: var(--loader-size);
  border: var(--loader-border-size) solid var(--loader-border-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: rotateX 1s infinite linear;
}

.loader::before {
  content:"";
  border: var(--loader-border-size) solid var(--loader-border-color);
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  animation: rotateX 0.5s infinite linear reverse;
}
        
@keyframes rotateX {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>
